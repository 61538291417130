import React, { useState } from "react";
import Button from "Components/Button";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

export default function CreatingTotalMatch(props) {
    const { onClose, onClearConnection } = props;
    const [processing, setProcessing] = useState(false)

    const { t } = useTranslation("providers");

    const handleReject = async () => {
        setProcessing(true);
        await onClearConnection();
        onClose();
    }

    return (
        <div className="text-center">
            <img src="/img/icon-error.svg" alt="Icono de Error" />
            <h3 className="fw-500 mt-4">
                {t("creating-total-match-title")}
            </h3>
            <div className="mt-2 text-muted">
                {t("creating-total-match-description")}
            </div>
            <div className="mt-4">
                <Button primary size="large" isBusy={processing} onClick={handleReject}>
                    {t("close")}
                </Button>
            </div>
        </div>
    )
}

CreatingTotalMatch.propTypes = {
    onClose: PropTypes.func.isRequired,
    onClearConnection: PropTypes.func.isRequired,
};