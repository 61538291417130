import { rest } from 'msw'
import { API_HOST } from 'config'
import countries from "./fixtures/countries.json"
import timezones from "./fixtures/timezones.json"
import languages from "./fixtures/languages.json"
import currencies from "./fixtures/currencies.json"

const othersHandlers = [
    rest.get(`${API_HOST}/auth/countries`, (req, res, ctx) => {
        return res(
            ctx.json(countries),
        )
    }),
    rest.get(`${API_HOST}/auth/timezones`, (req, res, ctx) => {
        return res(
            ctx.json(timezones),
        )
    }),
    rest.get(`${API_HOST}/auth/languages`, (req, res, ctx) => {
        return res(
            ctx.json(languages),
        )
    }),
    rest.get(`${API_HOST}/auth/currencies`, (req, res, ctx) => {
        return res(
            ctx.json(currencies),
        )
    }),
]

export default othersHandlers