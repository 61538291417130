import React from "react";
import classes from "./Alert.module.css";
import clsx from "clsx";
import PropTypes from "prop-types";
import warningImage from "./assets/warning.svg";
import errorImage from "./assets/error.svg";
import emailImage from "./assets/email.svg";
import partyImage from "./assets/party.svg";
import close from "./assets/close.svg";
import IconButton from "Components/IconButton";
import Icon from "Components/Icon";
import Button from "Components/Button";

const iconImages = {
    warning: warningImage,
    error: errorImage,
    invitation: emailImage,
    affiliate: partyImage,
    "provider-connection": emailImage,
};

export default function Alert(props) {
    const { title, variant, variantProps, description, onClose, className, cta } = props;

    const image = variantProps?.image
        ? variantProps.image
        : iconImages[variant] || warningImage

    const showIconStatus = variantProps?.status === "error"

    return (
        <div role="alert" className={clsx(classes.alert, classes[variant], className)}>
            <div className={classes.icon}>
                <img src={image} alt="" />

                {showIconStatus && (
                    <div className={classes.iconStatus} />
                )}
            </div>
            <div className={classes.content}>
                <div>
                    <div className={classes.title}>{title}</div>
                    {description && <div className={classes.description}>{description}</div>}
                </div>
                {cta && (
                    <Button primary size="small" className={classes.cta} onClick={cta.onClick}>
                        <span className={classes.ctaContent}>
                            {cta.label}

                            <Icon
                                name="arrow-right-small"
                                color="currentColor"
                                size={18}
                                className={classes.arrow}
                            />
                        </span>
                    </Button>
                )}
            </div>

            {onClose && (
                <IconButton className={classes.close} onClick={onClose}>
                    <img src={close} alt="" />
                </IconButton>
            )}
        </div>
    );
}

Alert.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    variant: PropTypes.string,
    variantProps: PropTypes.object,
    cta: PropTypes.shape({
        label: PropTypes.string,
        onClick: PropTypes.func
    }),
    onClose: PropTypes.func,
    className: PropTypes.string,
};