import React, { useState } from "react";
import Button from "Components/Button";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

export default function UnknownErrorMessage(props) {
    const { onClearConnection, onClose } = props
    const [processing, setProcessing] = useState(false)

    const { t } = useTranslation("providers");

    const handleReject = async () => {
        setProcessing(true)
        await onClearConnection()
        onClose()
        setProcessing(false)
    }

    return (
        <div className="text-center">
            <img src="/img/icon-error.svg" alt="Icono de Error" />
            <h3 className="fw-500 mt-4">
                {t("error-connecting")}
            </h3>
            <div className="text-muted">
                {t("error-connecting-description")}
            </div>

            <div className="mt-4">
                <Button primary size="large" isBusy={processing} onClick={handleReject}>
                    {t("accept")}
                </Button>
            </div>
        </div>
    )
}

UnknownErrorMessage.propTypes = {
    onClearConnection: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    description: PropTypes.string.isRequired,
}
