import React from "react";
import SearchField from "Components/Search";
import UpgradeButton from "Components/UpgradeButton";
import HeaderOnboarding from "Components/HeaderOnboarding";
import AppLayoutProfileMenu from "./AppLayoutProfileMenu";

export default function AppLayoutHeaderDesktop() {

    return (
        <div className="d-flex align-items-center gap-2">
            <SearchField className="flex-grow-1" />

            <div className="d-flex gap-2 align-items-center">
                <HeaderOnboarding />

                <AppLayoutProfileMenu className="flex-shrink-0" />

                <UpgradeButton />
            </div>
        </div>
    )
}