import React, { useEffect, useDeferredValue, useCallback } from "react";
import { getCookie, setCookie } from "Utils/cookies";
import { sendUserEvent } from "Services/user";
import { trackError } from "Utils/errorMonitoring";
import { IS_PRODUCTION } from "config";
import { useLocation } from "react-router-dom";
import PropTypes from 'prop-types';
import { TrackingContext } from "./TrackingContext";
import Analytics from 'analytics'
import googleTagManager from '@analytics/google-tag-manager'
import * as errorMonitoring from 'Utils/errorMonitoring';
import { useUser } from 'Components/UserProvider/useUser';
import { ROUTES, COOKIES } from "Utils/constants";
import analyticsLogger from "Utils/analyticsPluginLogger";
import segmentPlugin from '@analytics/segment'
import { getChatToken } from "Services/others";
import amplitudeReplay from "./amplitudeReplay";

amplitudeReplay.init();

const EXPIRE_INDENTIFIED = 30 * 60 * 1000;
const analyticsPlugins = IS_PRODUCTION
    ? [googleTagManager({ containerId: 'GTM-P6F6GFW' }), segmentPlugin({ writeKey: '5HaSsgjkVceKzpTyFZFY9lrL4L2ChsUI' })]
    : [analyticsLogger()];

const analytics = Analytics({
    app: 'banktrack',
    plugins: analyticsPlugins
})

const getRouteEventName = (pathname) => {
    for (const routeID in ROUTES) {
        const route = ROUTES[routeID];
        if (pathname === route) {
            return routeID;
        }
    }

    for (const routeID in ROUTES) {
        const route = ROUTES[routeID];
        if (pathname.startsWith(route)) {
            return routeID;
        }
    }

    return pathname.match(/[^/]+/g)[0];
}

const trackSegmentPageView = (pathname) => {
    if (pathname === '/') return

    if (IS_PRODUCTION && typeof window.analytics?.page === "function") {
        const eventName = getRouteEventName(pathname);
        analytics.page(eventName, {
            path: pathname
        });
    }
}

export default function TrackingProvider({ children }) {
    const { user } = useUser();
    const { pathname } = useLocation();
    const deferredPath = useDeferredValue(pathname)

    useEffect(() => {
        trackSegmentPageView(deferredPath);
    }, [deferredPath]);

    useEffect(() => {
        if (!user) return;

        getChatToken()
            .then((chatToken) => {
                window.hsConversationsSettings = {
                    identificationEmail: user.email,
                    identificationToken: chatToken,
                };

                if (window.HubSpotConversations) {
                    window.HubSpotConversations.widget.load();
                }
            })
            .catch(trackError);
    }, [user]);

    // track user session
    useEffect(() => {
        const identifyUser = async () => {
            const allReadyIdentified = getCookie(COOKIES.IDENTIFIED);
            if (!allReadyIdentified && user) {
                try {
                    await sendUserEvent({ event_name: "user_session_started" });
                    setCookie(COOKIES.IDENTIFIED, 1, EXPIRE_INDENTIFIED);
                } catch (error) {
                    trackError(error);
                }
            }
        }

        const onChangeVisibility = () => {
            if (document.visibilityState === 'visible') {
                identifyUser();
            }
        }

        document.addEventListener("visibilitychange", onChangeVisibility);
        identifyUser();

        return () => {
            document.removeEventListener('visibilitychange', onChangeVisibility);
        }
    }, [user]);

    // identify user in chat
    useEffect(() => {
        if (!user || !window.gist) return;
        // https://docs.getgist.com/article/28-the-gist-javascript-api

        const gistChatReady = () => window.gist.identify(user.email);
        window.addEventListener("gistChatReady", gistChatReady);

        window.gist.identify(user.email);
        return () => {
            window.removeEventListener("gistChatReady", gistChatReady);
        }
    }, [user]);

    // identify user by id
    useEffect(() => {
        if (!user?.id) return;

        analytics.identify(user.id)
    }, [user?.id]);

    // identify user in error monitoring
    useEffect(() => {
        if (!user?.email) return;

        errorMonitoring.setUserEmail(user.email);
    }, [user?.email]);


    const track = useCallback((eventName, eventProperties = {}) => {
        analytics.track(eventName, eventProperties, {
            plugins: {
                segment: false
            }
        });
    }, [])

    return (
        <TrackingContext.Provider value={{ track }}>
            {children}
        </TrackingContext.Provider>
    )
}

TrackingProvider.propTypes = {
    children: PropTypes.node.isRequired
}