import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { setCookie, getCookie } from "Utils/cookies";
import { COOKIES } from "Utils/constants";

const ONE_MONTH = 30 * 24 * 60 * 60 * 1000;

const useAffiliateCode = () => {
    const [searchParams] = useSearchParams();
    const [affiliateCode, setAffiliateCode] = useState(() => {
        return getCookie(COOKIES.AFFILIATE_CODE) ?? null
    });

    const affiliateCodeParam = searchParams.get("aff_code");

    useEffect(() => {
        if (affiliateCodeParam) {
            setCookie(COOKIES.AFFILIATE_CODE, affiliateCodeParam, ONE_MONTH);
            setAffiliateCode(affiliateCodeParam);
        }
    }, [affiliateCodeParam]);

    return affiliateCode;
}

export default useAffiliateCode;