import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { createProviderConnectionWithWidget } from "Services/providersConnections";
import { useErrors } from "Components/ErrorsProvider";
import Loading from "Components/Loading";

function loadScript(src, id) {
    return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.src = src;
        script.id = id;
        script.async = true;
        script.onload = resolve;
        script.onerror = reject;
        document.body.appendChild(script);
    });
}

function removeScript(id) {
    const script = document.getElementById(id);
    if (script) {
        script.remove();
    }
}

const WIDGET_URL = "https://widget.wealthreader.com/js/load.js";

export default function ProviderConnectionConnectWithWidget(props) {
    const { showUnknownError } = useErrors();

    const [isLoading, setIsLoading] = useState(false);
    const { providerConnectionId, connectorId, providerName, providerLogoUrl, onCancel, serviceProviderId, onConnect } = props;

    useEffect(() => {
        setIsLoading(true);

        let token;

        const initialize = async () => {
            try {
                token = await createProviderConnectionWithWidget({ connectorId, providerConnectionId, name: providerName });
                window.wr_conf = {
                    operation_id: token,
                    default_login: serviceProviderId,
                    wait_full_response: true
                }

                await loadScript(WIDGET_URL, "wealthreader-widget");

            } catch (error) {
                showUnknownError(error);
                onCancel();
            } finally {
                setIsLoading(false);
            }
        }

        initialize();

        const handleWidgetMessage = (e) => {
            if (e.data === "flow completed") {
                onConnect(token);
            }
        }

        window.addEventListener("message", handleWidgetMessage);

        return () => {
            window.removeEventListener("message", handleWidgetMessage);
            removeScript("wealthreader-widget");
        }
    }, []);

    return (
        <div className="text-center p-4">
            <div className="d-flex align-items-center justify-content-center">
                <img src={providerLogoUrl} alt={`Logo de ${providerName}`} style={{ height: "4rem" }} />
                <h4 className="fw-500 m-0 ms-3">{providerName}</h4>
            </div>

            {isLoading && (
                <Loading />
            )}

            <iframe id="wr-iframe" title="Wealth Reader widget" width="100%" referrerPolicy="origin"></iframe>
        </div>
    )
}

ProviderConnectionConnectWithWidget.propTypes = {
    connectorId: PropTypes.string.isRequired,
    providerName: PropTypes.string.isRequired,
    providerLogoUrl: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
    serviceProviderId: PropTypes.string.isRequired,
    onConnect: PropTypes.func.isRequired,
    providerConnectionId: PropTypes.string,
};
