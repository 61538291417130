import React, { useState } from "react";
import PropTypes from "prop-types";
import ProviderConnectionConnect from "Sections/ProviderConnectionConnect";
import Button from "Components/Button";
import { useTranslation } from "react-i18next";

export default function InvalidCredentials(props) {
    const { providerConnection, onEnableClose, onClose, onClearConnection } = props;
    const [showConnect, setShowConnect] = useState(false)
    const [processing, setProcessing] = useState(false)

    const { t } = useTranslation("providers");

    const handleRetry = () => {
        onClearConnection()
        setShowConnect(true)
        onEnableClose()
    }

    const handleCancel = async () => {
        setProcessing(true)
        await onClearConnection(false)

        setProcessing(false)
        onClose()
    }

    if (!showConnect) {
        return (
            <div className="text-center mb-3">
                <img src="/img/icon-error.svg" alt="Icono de Error" />
                <h4 className="mt-4">
                    {t("invalid-credentials-title")}
                </h4>
                <div className="text-muted">
                    {t("invalid-credentials-description")}
                </div>
                <div className="d-flex justify-content-center gap-2 mt-4">
                    <Button primary size="large" onClick={handleRetry}>
                        {t("retry")}
                    </Button>
                    <Button size="large" variant="outlined" isBusy={processing} onClick={handleCancel}>
                        {t("cancel")}
                    </Button>
                </div>
            </div>
        )
    }


    return (
        <ProviderConnectionConnect
            onConnect={onClose}
            onCancel={onClose}
            connector={providerConnection.connector}
            providerConnectionId={providerConnection.provider_connection_reference_id}
            onError={onClose}
        />
    )
}

InvalidCredentials.propTypes = {
    providerConnection: PropTypes.object.isRequired,
    description: PropTypes.string.isRequired,
    info: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    onEnableClose: PropTypes.func.isRequired,
    onClearConnection: PropTypes.func.isRequired,
};