import axiosInstance from "Utils/axiosInstance";
import { mapDtoToProduct } from "Utils/mappers";
import { formatCurrency } from "Utils/formatters";
import { sortItems } from "Utils/mappers";

const mapDtoToProviderConnection = (dto) => {
    const getHelpDescription = (product) => {
        const balanceText = `Saldo: ${formatCurrency(product.balance, { currency: product.currency })}`;

        if (product.iban) {
            return `${product.iban} · ${balanceText}`;
        }

        return `${product.product_id} · ${balanceText}`;
    };

    return {
        ...dto,
        products: dto.products
            .map(mapDtoToProduct)
            .map(product => ({ ...product, help_description: getHelpDescription(product) }))
            .sort(sortItems)
            .sort((a, b) => a.is_deleted - b.is_deleted),
        creation_date: new Date(dto.creation_date),
        last_dump: dto.last_dump ? new Date(dto.last_dump) : null,
        next_dump: dto.next_dump ? new Date(dto.next_dump) : null,
    };
}

async function getConnector(id) {
    const response = await axiosInstance.get(`/providers/connectors/${id}`);

    if (!response?.data) return null;

    return response?.data;
}


export async function getProvidersConnections() {
    const response = await axiosInstance.get("/providers/connections");

    if (!response?.data) return [];

    const connections = response.data
        .filter(item => !item.is_deleted)
        .map(async item => {
            const connector = await getConnector(item.provider_connector_id);
            return { ...item, connector };
        })

    return Promise.all(connections).then((connections) => connections.map(mapDtoToProviderConnection).sort(sortItems));
}

export async function confirmProviderConnection(id) {
    const response = await axiosInstance.post(`/providers/connections/${id}/confirm`, { action: "confirm" });

    return response?.data;
}

export async function rejectProviderConnection(id) {
    const response = await axiosInstance.post(`/providers/connections/${id}/confirm`, { action: "cancel" });

    return response?.data;
}


export async function synchronizeProviderConnection(id, completeSync, otp = null, session_id = null, counter_id = null) {

    const response = await axiosInstance.post(`/providers/connections/${id}/synchronize`, {
        'complete_sync': completeSync,
        'meta': session_id !== null ? {
            'otp': otp,
            'session_id': session_id,
            'counter_id': counter_id
        } : null
    });

    return response?.data;

}

export async function getProviderConnectionRedirectUrl({ connectorId, name, providerConnectionId = null }) {
    try {
        await axiosInstance.post("/providers/connections", {
            name,
            provider_connector_id: connectorId,
            ref_provider_connection_id: providerConnectionId,
            meta: null,
        });
    } catch (error) {
        if (error.response?.status === 400 && error.response?.data?.description === "requires_redirection") {
            return error.response.data.info.url;
        }
        throw error;
    }
}

export async function createProviderConnectionWithWidget({ connectorId, name, providerConnectionId = null }) {
    try {
        await axiosInstance.post("/providers/connections", {
            name,
            provider_connector_id: connectorId,
            ref_provider_connection_id: providerConnectionId,
            meta: null,
        });
    } catch (error) {
        if (error.response?.status === 400 && error.response?.data?.description === "requires_widget") {
            return error.response.data.info.token;
        }
        throw error;
    }
}

export async function createProviderConnectionWithSelector({ connectorId, name, providerConnectionId = null, token }) {
    try {
        await axiosInstance.post("/providers/connections", {
            name,
            provider_connector_id: connectorId,
            ref_provider_connection_id: providerConnectionId,
            meta: {
                token
            },
        });
    } catch (error) {
        if (error.response?.status === 400 && error.response?.data?.description === "requires_processing") {
            return error.response.data.info.token;
        }
        throw error;
    }
}

export async function createProviderConnectionManual({ connectorId, name, products, providerConnectionId = null }) {
    const response = await axiosInstance.post("/providers/connections", {
        name,
        provider_connector_id: connectorId,
        ref_provider_connection_id: providerConnectionId,
        meta: {
            products
        }
    });

    return response?.data;
}

export async function createProviderConnection({ connectorId, name, credentials, counterId = null, documentType = null, accountId = null, sessionId = null, otp = null, providerConnectionId = null }) {
    try {
        await axiosInstance.post("/providers/connections", {
            name,
            provider_connector_id: connectorId,
            ref_provider_connection_id: providerConnectionId,
            meta: {
                document_type: documentType === null ? null : `${documentType}`,
                credentials,
                account_id: accountId === null ? null : `${accountId}`,
                session_id: sessionId,
                counter_id: counterId === null ? null : `${counterId}`,
                otp
            },
        });
    } catch (error) {
        if (error.response?.status === 400 && error.response?.data?.description === "requires_processing") {
            return error.response.data.info.token;
        }
        throw error;
    }
}

export async function createProviderConnectionWealthReader({ connectorId, name, documentType, credentials, contractName, sessionId, otp, providerConnectionId = null }) {
    try {
        await axiosInstance.post("/providers/connections", {
            name,
            provider_id: connectorId,
            provider_connection_id: providerConnectionId,
            meta: {
                document_type: documentType,
                credentials,
                contract_name: contractName,
                session_id: sessionId,
                otp: otp
            },
        });
    } catch (error) {
        if (error.response?.status === 400 && error.response?.data?.description === "requires_processing") {
            return error.response.data.info.token;
        }
        throw error;
    }
}

export async function createProviderConnectionWithCredentials({ connectorId, name, credentials, counterId = null, documentType = null, accountId = null, sessionId = null, otp = null, providerConnectionId = null }) {
    try {
        await axiosInstance.post("/providers/connections", {
            name,
            provider_connector_id: connectorId,
            ref_provider_connection_id: providerConnectionId,
            meta: {
                document_type: documentType === null ? null : `${documentType}`,
                credentials,
                account_id: accountId === null ? null : `${accountId}`,
                session_id: sessionId,
                counter_id: counterId === null ? null : `${counterId}`,
                otp
            },
        });
    } catch (error) {
        if (error.response?.status === 400 && error.response?.data?.description === "requires_processing") {
            return error.response.data.info.token;
        }
        throw error;
    }
}


export async function getProviderConnection(id) {
    const response = await axiosInstance.get(`/providers/connections/${id}`);

    if (!response?.data) return null;

    const connector = await getConnector(response.data.provider_connector_id);

    return mapDtoToProviderConnection({ ...response.data, connector });
}

export async function sortProviderConnections(ids) {
    await axiosInstance.post("/providers/connections/sort", ids);
}

export async function deleteProviderConnection(id) {
    await axiosInstance.delete(`/providers/connections/${id}`);
}

export async function editProviderConnection(id, { name, is_manual_sync }) {
    const response = await axiosInstance.put(`/providers/connections/${id}`, {
        name,
        is_manual_sync,
    });
    return response?.data;
}

export async function associateProviderConnection(providerId, reportsConnections) {
    await axiosInstance.post('/reports/associate', {
        reports_connections_ids: reportsConnections.map(item => item.id),
        provider_connection_id: providerId
    });
}

export async function updateProduct(providerConnectionId, productId, product) {
    const response = await axiosInstance.put(`/providers/connections/${providerConnectionId}/products/${productId}`, product);
    return response?.data;
}


const mapDtoToSyncHistory = (dto) => {
    return {
        ...dto,
        creation_date: new Date(dto.creation_date),
    };
}

export async function getSyncHistory(providerConnectionId, searchParams) {
    const params = new URLSearchParams(searchParams);

    const response = await axiosInstance.get(`/providers/connections/${providerConnectionId}/synchronizations`, { params });

    if (!response?.data) return {};

    return {
        ...response.data,
        items: response.data.items.map(mapDtoToSyncHistory),
    }
}

export async function getSyncHistoryLogs(providerConnectionId, syncId) {
    const response = await axiosInstance.get(`/providers/connections/${providerConnectionId}/synchronizations/${syncId}/logs`);

    if (!response?.data) return [];

    return response.data;
}

export async function setProviderConnectionBusinessForce(connectionId, isBusinessForce) {
    const response = await axiosInstance.patch(`/providers/connections/${connectionId}/support`, {
        is_business_force: isBusinessForce
    });

    return response?.data;
}


export async function updateProviderConnector(id, data) {
    const response = await axiosInstance.patch(`/providers/connectors/${id}`, data);

    return response?.data;
}