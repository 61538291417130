
import PropTypes from "prop-types";
import React from "react";

const Mail = ({ color, size, ...props }) => (
    <svg viewBox="0 0 16 16" width={size} height={size} {...props}>
        
	<path d="M1.885 3.597A.838.838 0 0 1 2.683 3H13.35c.375 0 .696.253.799.597L8.017 7.889 1.885 3.597ZM1.85 4.793v7.04c0 .457.376.834.833.834H13.35a.837.837 0 0 0 .833-.834v-7.04L8.303 8.91a.5.5 0 0 1-.573 0L1.85 4.793Zm13.333-.951v7.991c0 1.01-.823 1.834-1.833 1.834H2.683A1.837 1.837 0 0 1 .85 11.833v-8C.85 2.823 1.674 2 2.683 2H13.35c1.005 0 1.827.817 1.833 1.821V3.842Z" fill={color} fillRule="evenodd"/>

    </svg>
);

export default Mail;

Mail.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
