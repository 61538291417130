import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getUserSettings, createUserSetting } from "Services/user";
import { trackError } from "Utils/errorMonitoring";

export default function useSettingsStorage() {
    const { data, isLoading } = useQuery(['settings-storage'], getUserSettings, {
        onError: (error) => {
            trackError(error);
        }
    });

    const queryClient = useQueryClient();

    const { mutate: setSetting } = useMutation(createUserSetting, {
        onMutate: (payload) => {
            queryClient.setQueryData(['settings-storage'], (old) => {
                if (!old) return old;

                return {
                    ...old,
                    [payload.field]: {
                        creation_date: new Date().toISOString(),
                        value: payload.value,
                        is_global: payload.is_global
                    }
                }
            });
        }
    });

    return {
        settings: data,
        isLoading,
        setSetting
    }

}