import React, { useState } from "react";
import Button from "Components/Button";
import classes from "./AppLayout.module.css";
import FullScreenDialog from "Components/FullScreenDialog";
import { useQuery } from "@tanstack/react-query";
import { getInvitations } from "Services/user";
import { trackError } from "Utils/errorMonitoring";
import { useQueryClient } from "@tanstack/react-query";
import { updateInvitation } from "Services/user";
import { useErrors } from "Components/ErrorsProvider";
import AvatarUser from "Components/AvatarUser";
import Icon from "Components/Icon";
import { useUser } from "Components/UserProvider/useUser";
import { Trans, useTranslation } from "react-i18next";
import Alert from "Components/Alert";

const captilize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

const ACTIONS = {
    ACCEPT: "accept",
    REJECT: "reject",
}

export default function AppLayoutInvitationsCTA() {
    const [showModal, setShowModal] = useState(false);
    const { user } = useUser();
    const { data = [], loading } = useQuery(["invitations"], getInvitations, {
        onError: trackError,
    });
    const { t } = useTranslation("common");
    const [processingAction, setProcessingAction] = useState(null);
    const { showUnknownError } = useErrors();

    const queryClient = useQueryClient();

    if (loading) {
        return null;
    }

    if (data.length === 0) {
        return null;
    }

    const invitation = data[0]

    const handleAccept = async () => {
        setProcessingAction(ACTIONS.ACCEPT);
        try {
            await updateInvitation(invitation.id, { action: ACTIONS.ACCEPT });
            await queryClient.invalidateQueries();
        } catch (error) {
            showUnknownError(error);
        } finally {
            setProcessingAction(null);
            setShowModal(false);
        }
    }

    const handleReject = async () => {
        setProcessingAction(ACTIONS.REJECT);
        try {
            await updateInvitation(invitation.id, { action: ACTIONS.REJECT });
            await queryClient.invalidateQueries(["invitations"]);
        } catch (error) {
            showUnknownError(error);
        } finally {
            setProcessingAction(null);
            setShowModal(false);
        }
    }

    return (
        <>
            <FullScreenDialog
                open={showModal}
                onClose={() => setShowModal(false)}
                size="small"
                showCloseButton
                classes={{
                    container: classes.invitationModal,
                }}
            >
                <div className="pb-4 m-auto">
                    <div className="text-center mb-4">
                        <div className="d-flex align-items-center justify-content-center mb-4">
                            <AvatarUser className={classes.shadow} name={invitation.name} size={65} />
                            <div className={classes.joinAvatar}>
                                <Icon name="plus" size={29} color="#552EDA" />
                            </div>
                            <AvatarUser className={classes.shadow} name={user.name} size={65} />
                        </div>
                        <h4>
                            <Trans t={t} i18nKey="invitation-join">
                                {{ name: captilize(invitation.name) }}
                            </Trans>
                        </h4>
                        <div className="text-muted pb-2">
                            <Trans t={t} i18nKey="invitation-join-description">
                                {{ name: captilize(invitation.name) }}
                            </Trans>
                        </div>
                    </div>

                    <div className="d-flex gap-2 justify-content-center" style={{ pointerEvents: processingAction ? "none" : "all" }}>
                        <Button primary size="large" className={"mt-4 mt-md-0"} isBusy={processingAction === ACTIONS.ACCEPT} onClick={handleAccept}>
                            {t("accept")}
                        </Button>
                        <Button variant="outlined" size="large" className={"mt-4 mt-md-0"} isBusy={processingAction === ACTIONS.REJECT} onClick={handleReject}>
                            {t("reject")}
                        </Button>
                    </div>
                </div>
            </FullScreenDialog>

            <Alert
                className={classes.spaceBottom}
                title={t("invitation-pending")}
                description={
                    <Trans t={t} i18nKey="invitation-pending-description">
                        {{ name: invitation.name }}
                    </Trans>
                }
                variant="invitation"
                cta={{
                    label: t("invitation-check"),
                    onClick: () => setShowModal(true)
                }}
            />
        </>
    );
}
