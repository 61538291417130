
import PropTypes from "prop-types";
import React from "react";

const ExclamationTriangle = ({ color, size, ...props }) => (
    <svg viewBox="0 0 16 16" width={size} height={size} {...props}>
        
	<path d="M1.588 11.91c-.718 1.332.247 2.947 1.76 2.947h9.304c1.513 0 2.478-1.615 1.76-2.948L9.762 3.271c-.755-1.403-2.767-1.403-3.522 0l-4.651 8.638Zm5.27-5.053a1.143 1.143 0 0 1 2.285 0V8a1.143 1.143 0 1 1-2.286 0V6.857ZM8 10.285a1.143 1.143 0 1 0 0 2.286 1.143 1.143 0 0 0 0-2.286Z" fill={color} fillRule="evenodd"/>

    </svg>
);

export default ExclamationTriangle;

ExclamationTriangle.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
