import React, { useState } from "react";
import classes from "./AppLayout.module.css";
import useProvidersConnections from "Hooks/useProvidersConnections";
import { PROVIDER_CONNECTION_STATUSES } from "Utils/constants";
import ProviderConnectionConnectDialog from "Sections/ProviderConnectionConnectDialog";
import { useQueryClient } from "@tanstack/react-query";
import { Trans, useTranslation } from "react-i18next";
import Alert from "Components/Alert";

const MODAL_TYPES = {
    SUCCESS: "success",
    CONNECT: "connect",
};

const getExpiredProvider = (providersConnections) => {
    const expiredProviders = providersConnections
        .filter(item => [PROVIDER_CONNECTION_STATUSES.EXPIRED].includes(item.status))
        .filter(item => item.products.some(product => !product.is_hidden && !product.is_deleted))
        .filter(item => !item.is_premium_blocked);

    if (expiredProviders.length === 0) {
        return null;
    }

    return expiredProviders[0];
}

export default function AppLayoutProvidersCTA() {
    const [showModal, setShowModal] = useState(null);
    const { validProvidersConnections: providersConnections } = useProvidersConnections();
    const expiredProvider = getExpiredProvider(providersConnections);

    const { t } = useTranslation("common");

    const queryClient = useQueryClient();

    const closeDialog = () => setShowModal(null);

    const handleConnect = () => {
        setShowModal(null)
        queryClient.invalidateQueries(["providers-connections"]);
    }

    if (!expiredProvider || !expiredProvider.connector) {
        return null;
    }

    return (
        <>
            <ProviderConnectionConnectDialog
                open={showModal === MODAL_TYPES.CONNECT}
                onClose={closeDialog}
                onConnect={handleConnect}
                providerConnection={expiredProvider}
            />

            <Alert
                className={classes.spaceBottom}
                title={t("provider-connections.connection-error")}
                description={
                    <Trans t={t} i18nKey="provider-connections.connection-error-description">
                        {{ provider: expiredProvider.name }}
                    </Trans>
                }
                variant="provider-connection"
                variantProps={{
                    image: expiredProvider.logo_url,
                    status: "error"
                }}
                cta={{
                    label: t("provider-connections.fix-connection"),
                    onClick: () => setShowModal(MODAL_TYPES.CONNECT)
                }}
            />
        </>
    );
}
