import React, { useState } from "react";
import Button from "Components/Button";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

export default function ServiceOut(props) {
    const { onClearConnection, onClose, info } = props;
    const [processing, setProcessing] = useState(false)

    const { t } = useTranslation("providers");

    const handleReject = async () => {
        setProcessing(true);
        await onClearConnection();
        setProcessing(false);
        onClose();
    }

    return (
        <div className="text-center">
            <img src="/img/icon-error.svg" alt="Icono de Error" />
            <h4 className="mt-4">
                {t("service-out-title")}
            </h4>
            <div className="text-muted">
                {t("service-out-description")}
            </div>

            {info.service_message && (
                <div className="mt-4">
                    <div className="text-very-small text-start text-muted mb-1">
                        {t("service-out-provider-message")}
                    </div>
                    <div className="alert alert-warning" role="alert">
                        {info.service_message}
                    </div>
                </div>
            )}

            <Button primary size="large" isBusy={processing} className="mt-4" onClick={handleReject}>
                {t("accept")}
            </Button>
        </div>
    )
}

ServiceOut.propTypes = {
    onClearConnection: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    info: PropTypes.object.isRequired,
};