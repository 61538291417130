import React, { useState } from "react";
import FormProvider, { RHFSelect, RHFTextField } from 'Components/HookForm';
import { useForm } from 'react-hook-form';
import Button from "Components/Button";
import PropTypes from "prop-types";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import classes from "./ProviderConnectionsErrorManager.module.css";
import Radio, { RadioGroup } from "Components/Radio";
import { useTranslation } from "react-i18next";

const normalizeString = (string) => string.replace(/[^a-z0-9]/gi, '_').toLowerCase();

export default function MultiOTPFormWithCredentials(props) {
    const {
        onSubmit, fieldsNames, documentType, fieldsDescriptions,
        fieldsTypes, documentTypes, onCancel, fieldValues,
        disabledFields, canceling, submitting, otpMethods
    } = props;

    const [otpMethod, setOtpMethod] = useState(() => {
        if (Array.isArray(otpMethods) && otpMethods.length > 0) {
            return `radio-${otpMethods[0].method_id}`;
        }
        return null;
    });

    const { t } = useTranslation("providers");

    const normalizedFieldsNames = fieldsNames.map((name) => normalizeString(name));
    const processing = canceling || submitting;
    const fields = fieldsNames.map((name, index) => ({
        label: name,
        name: normalizedFieldsNames[index],
        description: fieldsDescriptions[index],
        type: fieldsTypes[index],
    }));

    const defaultValues = {
        ...documentTypes && ({
            document_type: documentType,
        }),
        ...normalizedFieldsNames.reduce((acc, name, index) => {
            const value = Array.isArray(fieldValues) ? fieldValues[index] ?? "" : "";
            acc[name] = value
            return acc;
        }, {}),
    }

    const schema = yup.object().shape({
        ...documentTypes && ({
            document_type: yup.string().required(t("this-field-is-required")),
        }),
        ...normalizedFieldsNames.reduce((acc, name) => {
            if (disabledFields.includes(name)) return acc;

            acc[name] = yup.string().required(t("this-field-is-required"));
            return acc;
        }, {}),
    });

    const methods = useForm({
        resolver: yupResolver(schema),
        defaultValues
    });

    const { handleSubmit } = methods;

    const onSubmitForm = (data) => {

        if (processing) return;
        const { document_type, ...credentialsData } = data;

        const credentials = Object.values(credentialsData);
        const originalOtpMethod = otpMethods.find(a => `radio-${a.method_id}` === otpMethod);
        onSubmit({
            documentType: documentTypes ? documentTypes.indexOf(document_type) : null,
            counterId: originalOtpMethod?.method_id ?? null,
            credentials
        });
    }

    const documentTypesOptions = documentTypes?.map(documentType => ({
        id: documentType,
        label: documentType,
    }));

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmitForm)}>
            <div className="d-grid gap-4 text-start">
                <RadioGroup
                    name="otpmethod"
                    label={t("account")}
                    value={otpMethod}
                    onChange={ev => setOtpMethod(ev.target.value)}
                >
                    {otpMethods.map(otpMethod => (
                        <Radio id={`radio-${otpMethod.method_id}`} key={`radio-${otpMethod.method_id}`} value={`radio-${otpMethod.method_id}`}>
                            <div className="pt-1">{otpMethod.description}</div>
                        </Radio>
                    ))}
                </RadioGroup>

                <div className={classes.divider} />

                {documentTypes && (
                    <RHFSelect
                        disabled={disabledFields.includes("document_type")}
                        name="document_type"
                        label={t("document-type")}
                        options={documentTypesOptions}
                    />
                )}

                {fields.map((field, index) => (
                    <RHFTextField
                        key={index}
                        name={field.name}
                        label={field.label}
                        type={field.type}
                        disabled={disabledFields.includes(field.label)}
                        helperText={`${t("enter-your")} ${field.label.toLocaleLowerCase()}`}
                        inputProps={{
                            autoComplete: 'new-password',
                        }}
                    />
                ))}
            </div>

            <div className="pt-4 d-flex gap-2 justify-content-center">
                <Button size="large" primary type="submit" isBusy={submitting}>
                    <img alt="" src="/img/icon-padlock.svg" />
                    <span className={"ms-2"}>
                        {t("connect")}
                    </span>
                </Button>
                <Button size="large" variant="outlined" onClick={onCancel} isBusy={canceling}>
                    {t("cancel")}
                </Button>
            </div>
        </FormProvider>
    )
}


MultiOTPFormWithCredentials.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    fieldsNames: PropTypes.arrayOf(PropTypes.string).isRequired,
    fieldsDescriptions: PropTypes.arrayOf(PropTypes.string).isRequired,
    fieldsTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
    documentTypes: PropTypes.arrayOf(PropTypes.string),
    onCancel: PropTypes.func.isRequired,
    documentType: PropTypes.string,
    fieldValues: PropTypes.arrayOf(PropTypes.string),
    canceling: PropTypes.bool,
    submitting: PropTypes.bool,
    disabledFields: PropTypes.arrayOf(PropTypes.string).isRequired,
    otpMethods: PropTypes.arrayOf(PropTypes.shape({
        method_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        description: PropTypes.string.isRequired,
    })).isRequired,
}