import { IS_PRODUCTION, AMPLITUDE_API_KEY } from "config";
import * as amplitude from '@amplitude/analytics-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';

const amplitudeReplay = {
    init: () => {
        if (!IS_PRODUCTION) return;

        const sessionReplayTracking = sessionReplayPlugin();
        amplitude.add(sessionReplayTracking);
        amplitude.init(AMPLITUDE_API_KEY);
    },
}

export default amplitudeReplay;