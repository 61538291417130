import React, { useEffect } from "react";
import FormProvider, { RHFTextField } from 'Components/HookForm';
import { useForm } from 'react-hook-form';
import Button from "Components/Button";
import PropTypes from "prop-types";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useTranslation } from "react-i18next";

export default function RequiresOtpForm(props) {
    const { onSubmit, onCancel, canceling, submitting, showOtpError } = props;
    const processing = canceling || submitting;
    const defaultValues = {
        otp: "",
    }

    const { t } = useTranslation("providers");

    const schema = yup.object().shape({
        otp: yup.string().required(t("this-field-is-required")),
    });

    const methods = useForm({
        resolver: yupResolver(schema),
        defaultValues
    });

    const { handleSubmit, setError, watch, clearErrors } = methods;

    const otp = watch("otp");
    useEffect(() => {
        if (otp) {
            clearErrors("otp");
        }
    }, [otp]);

    useEffect(() => {
        if (showOtpError) {
            setError("otp", {
                type: "manual",
                message: t("otp-error-message")
            });
        }
    }, [showOtpError]);

    const onSubmitForm = (data) => {
        if (processing) return;

        onSubmit({
            otp: data.otp
        });
    }

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmitForm)}>
            <RHFTextField
                label={t("otp-label")}
                name="otp"
            />

            <div className="pt-4 d-flex gap-2 justify-content-center">
                <Button size="large" primary type="submit" isBusy={submitting}>
                    <img alt="" src="/img/icon-padlock.svg" />
                    <span className={"ms-2"}>
                        {t("connect")}
                    </span>
                </Button>
                <Button size="large" variant="outlined" onClick={onCancel} isBusy={canceling}>
                    {t("cancel")}
                </Button>
            </div>
        </FormProvider>
    )
}


RequiresOtpForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    canceling: PropTypes.bool,
    submitting: PropTypes.bool,
    showOtpError: PropTypes.bool,
}