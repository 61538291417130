import React from "react";
import Button from "Components/Button";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

export default function ReconnectingEmptyMatch(props) {
    const { onClearConnection, onClose } = props
    const [processing, setProcessing] = React.useState(false)

    const { t } = useTranslation("providers");

    const handleClick = async () => {
        setProcessing(true)
        await onClearConnection()
        onClose()
        setProcessing(false)
    }

    return (
        <div className={"text-center"}>
            <img src="/img/icon-error.svg" alt="" />
            <h3 className={"fw-500 mt-4"}>
                {t("reconnecting-empty-match-title")}
            </h3>
            <div className={"mt-2 text-muted"}>
                {t("reconnecting-empty-match-description")}
            </div>
            <div className="mt-4">
                <Button primary size="large" onClick={handleClick} isBusy={processing}>
                    {t("close")}
                </Button>
            </div>
        </div>
    )
}

ReconnectingEmptyMatch.propTypes = {
    onClearConnection: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
}
