import React from "react";
import clsx from "clsx";
import classes from "./Drawer.module.css";
import PropTypes from "prop-types";
import IconButton from "Components/IconButton";
import Icon from "Components/Icon";

export function DrawerHeader(props) {
    const { title, onClose, className, gutterBottom, onBack } = props;

    return (
        <div className={clsx(classes.header, gutterBottom && "mb-3", className)}>
            {onBack && (
                <IconButton onClick={onBack} className={classes.back}>
                    <Icon size={24} name="back-2" color="#C0BFBE" />
                </IconButton>
            )}

            {!!title && <h2 className={classes.title}>{title}</h2>}

            {onClose && (
                <IconButton onClick={onClose} className={classes.close}>
                    <Icon size={24} name="close" color="#C0BFBE" />
                </IconButton>
            )}
        </div>
    )
}

DrawerHeader.propTypes = {
    className: PropTypes.string,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    onClose: PropTypes.func,
    gutterBottom: PropTypes.bool,
    onBack: PropTypes.func,
}

