import React, { useState } from "react";
import Button from "Components/Button";
import PropTypes from "prop-types";
import Radio, { RadioGroup } from "Components/Radio";
import { useTranslation } from "react-i18next";

export default function RequiresOtpForm(props) {
    const {
        onSubmit, onCancel,
        canceling, submitting, accounts
    } = props;

    const [account, setAccount] = useState(() => {
        if (Array.isArray(accounts) && accounts.length > 0) {
            return `radio-${accounts[0].account_id}`;
        }
        return null;
    });

    const { t } = useTranslation("providers");

    const processing = canceling || submitting;

    const handleSubmit = (ev) => {
        ev.preventDefault();
        if (processing) return;

        const originalAccount = accounts.find(a => `radio-${a.account_id}` === account);
        onSubmit({
            accountId: originalAccount?.account_id ?? null,
        });
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="d-grid gap-4 text-start">
                <RadioGroup name="account" label={t("account")} value={account} onChange={ev => setAccount(ev.target.value)}>
                    {accounts.map(account => (
                        <Radio id={`radio-${account.account_id}`} key={`radio-${account.account_id}`} value={`radio-${account.account_id}`}>
                            <div className="pt-1">{account.description}</div>
                        </Radio>
                    ))}
                </RadioGroup>
            </div>

            <div className="pt-4 d-flex gap-2 justify-content-center">
                <Button size="large" primary type="submit" isBusy={submitting}>
                    <img alt="" src="/img/icon-padlock.svg" />
                    <span className={"ms-2"}>
                        {t("connect")}
                    </span>
                </Button>
                <Button size="large" variant="outlined" onClick={onCancel} isBusy={canceling}>
                    {t("cancel")}
                </Button>
            </div>
        </form>
    )
}


RequiresOtpForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    fieldsNames: PropTypes.arrayOf(PropTypes.string).isRequired,
    fieldsDescriptions: PropTypes.arrayOf(PropTypes.string).isRequired,
    fieldsTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
    documentTypes: PropTypes.arrayOf(PropTypes.string),
    onCancel: PropTypes.func.isRequired,
    documentType: PropTypes.string,
    fieldValues: PropTypes.arrayOf(PropTypes.string),
    canceling: PropTypes.bool,
    submitting: PropTypes.bool,
    disabledFields: PropTypes.arrayOf(PropTypes.string).isRequired,
    accounts: PropTypes.arrayOf(PropTypes.shape({
        account_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        description: PropTypes.string.isRequired,
    })).isRequired,
}