import React, { useState, useEffect } from "react";
import { Outlet } from 'react-router-dom';
import Footer from "Components/Footer";
import SyncProvider from "Components/SyncProvider";
import classes from "./AppLayout.module.css";
import storage from "Utils/storage";
import clsx from "clsx";
import { ROUTES } from "Utils/constants";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AppLayoutHeaderDesktop from "./AppLayoutHeaderDesktop";
import AppLayoutNavbar from "./AppLayoutNavbar";
import IconButton from "Components/IconButton";
import AppLayoutHeaderMobile from "./AppLayoutHeaderMobile";
import AppLayoutProvidersCTA from "./AppLayoutProvidersCTA";
import PinIcon from "./PinIcon";
import BannerAddProviderOnboarding from "Sections/BannerAddProviderOnboarding";
import navLogoOpen from "assets/nav-logo-open.png";
import navLogoClosed from "assets/nav-logo-closed.png";
import AppLayoutInvitationsCTA from "./AppLayoutInvitationsCTA";
import ProviderConnectionsProvider from "Components/ProviderConnectionsProvider";
import Container from "Components/Container";
import AppLayoutPaymentErrorCTA from "./AppLayoutPaymentErrorCTA";
import UserNoticesProvider from "./UserNoticesProvider";
import { Slot } from "nano-slots";

const FULLSCREEN_ROUTES = [ROUTES.dashboards, ROUTES.forecasts];

export default function AppLayout() {
    const [expanded, setExpanded] = useState(false);

    const [isPinnedMenu, setIsPinnedMenu] = useState(() => {
        const isPinnedMenu = storage.getItem("is-pinned-menu");

        return isPinnedMenu === "true";
    });

    const { pathname } = useLocation();
    const navigate = useNavigate();


    useEffect(() => {
        const from = sessionStorage.getItem('redirect');
        if (from) {
            sessionStorage.removeItem('redirect');

            if (from !== pathname) {
                navigate(from, { replace: true });
            }
        }
    }, []);

    const handleTogglePinnedMenu = () => {
        setIsPinnedMenu(prev => {
            storage.setItem("is-pinned-menu", !prev);

            return !prev;
        });
    }

    const handleHover = () => {
        setExpanded(true);
    }

    const handleLeave = () => {
        setExpanded(false);
    }

    const collapsed = !isPinnedMenu && !expanded;
    const isFullRoute = FULLSCREEN_ROUTES.some(route => pathname.startsWith(route));
    const ContainerComponent = isFullRoute ? "div" : Container;
    const containerClassName = clsx(
        "px-4",
        "px-md-0",
        !isFullRoute && "mt-4"
    );

    return (
        <ProviderConnectionsProvider>
            <SyncProvider>
                <UserNoticesProvider>
                    <div className={clsx(classes.app, isPinnedMenu && classes.pinned, expanded && classes.expanded)} >
                        <header className={classes.headerMobile}>
                            <AppLayoutHeaderMobile />
                        </header>

                        <div className={classes.nav} onMouseEnter={handleHover} onMouseLeave={handleLeave}>
                            <div className={classes.navContent}>
                                <div className={classes.navHeader}>
                                    <div className={classes.navHeaderTitle}>
                                        <Link to={ROUTES.dashboards}>
                                            <img src={collapsed ? navLogoClosed : navLogoOpen} alt="Logo de Banktrack" height={28} width={136} />
                                        </Link>
                                        <IconButton onClick={handleTogglePinnedMenu} className={classes.pinButton}>
                                            <PinIcon color="rgba(32,32,32, .5)" />
                                        </IconButton>
                                    </div>
                                </div>
                                <AppLayoutNavbar collapsed={collapsed} className="flex-grow-1" onClose={handleLeave} />
                            </div>
                        </div>

                        <div className={classes.content}>
                            <div className={classes.header}>
                                <ContainerComponent className="px-4 px-md-0">
                                    <AppLayoutHeaderDesktop />
                                </ContainerComponent>
                            </div>

                            <main className={classes.main}>
                                <ContainerComponent className="px-4 px-md-0">
                                    <BannerAddProviderOnboarding className={classes.spaceBottom} />
                                    <AppLayoutPaymentErrorCTA />
                                    <AppLayoutProvidersCTA />
                                    <AppLayoutInvitationsCTA />

                                    <Slot name="app-layout-notices" />

                                </ContainerComponent>

                                <ContainerComponent className={containerClassName}>
                                    <Outlet />
                                </ContainerComponent>

                                <Footer />
                            </main>
                        </div>
                    </div>
                </UserNoticesProvider>
            </SyncProvider>
        </ProviderConnectionsProvider>
    )
}
