import React, { Fragment } from "react";
import Container from "Components/Container"
import classes from "./Footer.module.css";
import { useTranslation } from "react-i18next";
import { LEGAL_ROUTES } from "Utils/constants";

const LOCALE_LINKS = {
    ES: [{
        i18nKey: "privacy",
        url: LEGAL_ROUTES.privacy.ES
    }, {
        i18nKey: "terms",
        url: LEGAL_ROUTES.terms.ES
    }],
    EN: [{
        i18nKey: "privacy",
        url: LEGAL_ROUTES.privacy.EN
    }, {
        i18nKey: "terms",
        url: LEGAL_ROUTES.terms.EN
    }]
}

export default function Footer() {
    const year = new Date().getFullYear();
    const { t, i18n } = useTranslation("footer");

    const links = LOCALE_LINKS[i18n.language] || LOCALE_LINKS.EN;

    return (
        <Container className="py-5 mt-5 opacity-25 text-small">
            <div className={classes.row}>
                <div>
                    {t("copy", { year })}
                </div>
                <div>
                    {links.map((link, index) => (
                        <Fragment key={index}>
                            {index > 0 && <span className="mx-3">/</span>}

                            <a href={link.url} target="_blank" rel="noreferrer" className="text-decoration-none">
                                {t(link.i18nKey)}
                            </a>
                        </Fragment>
                    ))}
                </div>
            </div>
        </Container>
    )
}
