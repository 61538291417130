import React, { useState } from "react";
import Button from "Components/Button";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

export default function NoProducts(props) {
    const { onClearConnection, onClose } = props
    const [processing, setProcessing] = useState(false)

    const { t } = useTranslation("providers");

    const handleClick = async () => {
        setProcessing(true)
        await onClearConnection()
        onClose()
        setProcessing(false)
    }

    return (
        <div className={"text-center"}>
            <img src="/img/icon-error.svg" alt="Icono de Error" />
            <h3 className="fw-500 mt-4">
                {t("no-products-title")}
            </h3>
            <div className="mt-2 text-muted">
                {t("no-products-description")}
            </div>
            <div className="mt-4">
                <Button primary size="large" onClick={handleClick} isBusy={processing}>
                    {t("close")}
                </Button>
            </div>
        </div>
    )
}

NoProducts.propTypes = {
    onClearConnection: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
}
