import { useQuery } from "@tanstack/react-query";
import { getProviders } from "Services/providers";
import { trackError } from "Utils/errorMonitoring";
import { useMemo } from "react";

export default function useProviders() {
    const { data: providers = [], isLoading } = useQuery(["providers"], getProviders, {
        onError: error => trackError(error),
    });

    const availableProviders = useMemo(() => {
        return providers.filter(item => item.is_enabled)
    }, [providers]);

    return {
        isLoading,
        availableProviders,
        providers
    }
}