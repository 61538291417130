import { trackError } from "Utils/errorMonitoring";
import { useInfiniteQuery } from "@tanstack/react-query";
import { getAlertsConnections } from "Services/alerts";

const RESULTS_PER_PAGE = 20;

export default function useInfiniteAlerts() {
    return useInfiniteQuery(["alerts-connections"], async ({ pageParam = 1 }) => {
        const response = await getAlertsConnections({
            page: pageParam
        });

        return response;
    }, {
        refetchOnWindowFocus: false,
        onError: (err) => trackError(err),
        getNextPageParam: (lastPage, allPages) => {
            const pagesLoaded = allPages.length
            const totalPages = Math.ceil(lastPage.count / RESULTS_PER_PAGE)
            return pagesLoaded < totalPages ? pagesLoaded + 1 : false
        }
    });
}
