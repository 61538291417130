import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { SnackbarContent } from "notistack";
import classes from "./Snackbar.module.css";
import Spinner from "Components/Spinner";

const SnackbarProcessing = forwardRef(function SnackbarProcessing(props, ref) {
    const { message } = props;

    return (
        <SnackbarContent ref={ref} className={classes.root}>
            <div className={classes.card}>
                <div className={classes.layout}>
                    <div>
                        <Spinner size={20} color="#5543C7" className={classes.spinner} />
                    </div>
                    <div className={classes.content}>
                        <div className="text-small">{message}</div>
                    </div>
                </div>
            </div>
        </SnackbarContent>
    );
});

export default SnackbarProcessing;

SnackbarProcessing.propTypes = {
    message: PropTypes.string.isRequired,
};